import {
    getModule,
    Module,
    MutationAction,
    VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { RegisterRequest } from "@/api/recruit-shop/request";
import * as RecruitAPI from "@/api/recruit-shop";
import { isSuccess } from "@/api/response";
import {RegisterResponse} from "@/api/recruit-shop/response";

const MODULE_NAME = "recruit-shop/register";

/**
 * 求人情報登録更新API（/regist-recruit）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Register extends VuexModule {
    // state
    registerResponse: RegisterResponse = {} as RegisterResponse;

    // getters
    get isSuccess() {
        return isSuccess(this.registerResponse);
    }

    get getMessage() {
        return this.registerResponse.message;
    }

    get getPreviewUrl() {
        if (this.registerResponse.results) {
            return this.registerResponse.results.previewUrl;
        } else {
            return "";
        }
    }

    // MutationActions
    @MutationAction
    async register(registerRequest: RegisterRequest) {
        const registerResponse = await RecruitAPI.register(registerRequest);
        return {
            registerResponse
        };
    }

    @MutationAction
    async clear() {
        return {
            registerResponse: {} as RegisterResponse
        };
    }
}

export default getModule(Register);
