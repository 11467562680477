import {
    getModule,
    Module,
    MutationAction,
    VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {RecruitRequest, RegisterRequest} from "@/api/recruit-shop/request";
import { RecruitResponse, RecruitResult } from "@/api/recruit-shop/response";
import * as RecruitAPI from "@/api/recruit-shop";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "recruit-shop/get";

/**
 * 求人情報取得API（/get-shop-recruit）のレスポンスを管理するストアモジュール
 */
@Module({dynamic: true, store, name: MODULE_NAME, namespaced: true})
class Get extends VuexModule {
    // state
    recruitResponse: RecruitResponse = {} as RecruitResponse;

    // getters
    get isSuccess() {
        return isSuccess(this.recruitResponse);
    }

    get getMessage() {
        return this.recruitResponse.message;
    }

    get getId() {
        return this.recruitResponse.results ? this.recruitResponse.results.id : null;
    }

    get getShopId() {
        return this.recruitResponse.results.shopId;
    }

    get getImagePath() {
        return this.recruitResponse.results.imagePath;
    }

    get getJobApplyDetail() {
        return this.recruitResponse.results.jobApplyDetail;
    }

    get getDesireJobRequiredFlg() {
        return this.recruitResponse.results.desireJobRequiredFlg;
    }

    get getNameRequiredFlg() {
        return this.recruitResponse.results.nameRequiredFlg;
    }

    get getKanaRequiredFlg() {
        return this.recruitResponse.results.kanaRequiredFlg;
    }

    get getBirthDateRequiredFlg() {
        return this.recruitResponse.results.birthDateRequiredFlg;
    }

    get getGenderRequiredFlg() {
        return this.recruitResponse.results.genderRequiredFlg;
    }

    get getTelRequiredFlg() {
        return this.recruitResponse.results.telRequiredFlg;
    }

    get getEmailRequiredFlg() {
        return this.recruitResponse.results.emailRequiredFlg;
    }

    get getZipcodeRequiredFlg() {
        return this.recruitResponse.results.zipcodeRequiredFlg;
    }

    get getStateRequiredFlg() {
        return this.recruitResponse.results.stateRequiredFlg;
    }

    get getMunicipalitiesRequiredFlg() {
        return this.recruitResponse.results.municipalitiesRequiredFlg;
    }

    get getBuildingRequiredFlg() {
        return this.recruitResponse.results.buildingRequiredFlg;
    }

    get getRemarksRequiredFlg() {
        return this.recruitResponse.results.remarksRequiredFlg;
    }

    get getDesireJobType1() {
        return this.recruitResponse.results.desireJobType1;
    }

    get getDesireJobType2() {
        return this.recruitResponse.results.desireJobType2;
    }

    get getDesireJobType3() {
        return this.recruitResponse.results.desireJobType3;
    }

    get getDesireJobType4() {
        return this.recruitResponse.results.desireJobType4;
    }

    get getDesireJobType5() {
        return this.recruitResponse.results.desireJobType5;
    }

    get getDesireJobType6() {
        return this.recruitResponse.results.desireJobType6;
    }

    get getDesireJobType7() {
        return this.recruitResponse.results.desireJobType7;
    }

    get getDesireJobType8() {
        return this.recruitResponse.results.desireJobType8;
    }

    get getDesireJobType9() {
        return this.recruitResponse.results.desireJobType9;
    }

    get getDesireJobType10() {
        return this.recruitResponse.results.desireJobType10;
    }

    get getSendCompletedMessage() {
        return this.recruitResponse.results.sendCompletedMessage;
    }

    get getJobApplySendingEmail() {
        return this.recruitResponse.results.jobApplySendingEmail;
    }

    // MutationActions
    @MutationAction
    async recruit(recruitRequest: RecruitRequest) {
        const recruitResponse = await RecruitAPI.recruit(recruitRequest);
        return {
            recruitResponse
        };
    }

    @MutationAction
    async clear() {
        this.recruitResponse = {} as RecruitResponse;
        return {
            recruitResponse: {} as RecruitResponse
        };
    }
}

export default getModule(Get);
