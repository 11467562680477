import { Component, Mixins, Prop } from "vue-property-decorator";
import Draggable from "vuedraggable";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIFormRow from "@/components/UIFormRow.vue";
// @ts-ignore
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import { RegisterRequest } from "@/api/recruit-shop/request";
import RecruitGetApi from "@/store/recruit-shop/get";
import RecruitRegisterAPI from "@/store/recruit-shop/register";
import Flash, { ErrorAlert } from "@/store/common/flash";
import _ from "lodash";
import Loading from "@/store/loading";
import { RecruitRequest } from "@/api/recruit-shop/request";
import UlFileRegister from "@/components/UlFileRegister.vue";
import {UlFileRegisiterTabType} from "@/utils/enums";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import WindowOpen from "@/utils/window-open";
import ShopAgreementAPI from "@/store/shop-agreement/get";
import { GetRequest as GetAgreementRequest } from "@/api/shop-agreement/request";
import Admin from "@/store/admin/admin";
import { BusinessServiceStorage } from "@/store/business-service-storage";


@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIFormRow,
    Draggable,
    UlFileRegister,
    quillEditor
  }
})
export default class NewEdit extends Mixins(RedirectWithAlert) {
  @Prop({type: String, required: true})
  shopId!: string;

  // タイトル
  headingMain = "求人管理";
  headingSub = "Recruit management";

  initImageUrl = null as string | null;
  allowedTypes = ["image/jpeg", "image/png", "image/gif","image/jpg"];
  tabType = UlFileRegisiterTabType.Photo;

  // パンくず
  breadCrumbs = [
    {text: "Webアプリ", disabled: true},
    {text: "求人管理", disabled: true},
    {
      text: "求人情報一覧",
      disabled: false,
      to: {name: "recruit-management"}
    },
    {text: "設定", disabled: true}
  ];

  editorOption = {
    modules: {
      toolbar: [
        [{ color: [] }, { background: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: [] }],
        ["link", "image"],
        ["clean"]
      ]
    }
  };

  // 契約状態
  agreements = {
    ap: false,
    line: false,
    web: false
  };


  // ------------
  // 変動値
  // ------------

  // ローディングステータス
  isLoading = false;

  // 入力データ
  inputData = {
    /** 求人情報ID */
    id: null as null | number,
    /** 店舗ID */
    shopId: Number(this.shopId),
    /** 画像編集フラグ */
    imageEditFlg: 0,
    /** 画像パス */
    imagePath: null as string | null,
    /** t_shop_image_histories.id */
    imageId: null as number | null,
    /** 求人応募詳細 */
    jobApplyDetail: "",
    /** 希望職種必須フラグ */
    desireJobRequiredFlg: 1,
    /** お名前必須フラグ */
    nameRequiredFlg: 1,
    /** フリガナ必須フラグ */
    kanaRequiredFlg: 1,
    /** 生年月日必須フラグ */
    birthDateRequiredFlg: 1,
    /** 性別必須フラグ */
    genderRequiredFlg: 1,
    /** 連絡先電話番号必須フラグ */
    telRequiredFlg: 1,
    /** メールアドレス必須フラグ */
    emailRequiredFlg: 1,
    /** 郵便番号必須フラグ */
    zipcodeRequiredFlg: 1,
    /** 都道府県必須フラグ */
    stateRequiredFlg: 1,
    /** 市区町村・番地必須フラグ */
    municipalitiesRequiredFlg: 1,
    /** 建物名・号室必須フラグ */
    buildingRequiredFlg: 1,
    /** 備考必須フラグ */
    remarksRequiredFlg: 1,
    /** 希望職種1 */
    desireJobType1: "",
    /** 希望職種2 */
    desireJobType2: "",
    /** 希望職種3 */
    desireJobType3: "",
    /** 希望職種4 */
    desireJobType4: "",
    /** 希望職種5 */
    desireJobType5: "",
    /** 希望職種6 */
    desireJobType6: "",
    /** 希望職種7 */
    desireJobType7: "",
    /** 希望職種8 */
    desireJobType8: "",
    /** 希望職種9 */
    desireJobType9: "",
    /** 希望職種10 */
    desireJobType10: "",
    /** 送信完了メッセージ */
    sendCompletedMessage: "",
    /** 求人応募情報送信先メールアドレス */
    jobApplySendingEmail: "",
    /** プレビュー */
    isPreview: null as number | null
  }  as RegisterRequest;

  //OEM事業者Id
  businessId = 0 as number;


  //ローディングステータス
  get loading() {
    return Loading.isLoading;
  }

  get shopName() {
    return this.$route.query.shopName ? this.$route.query.shopName : "";
  }

  /**
   * createdライフサイクルフック
   */
  async created() {

    // 契約情報を取得する
    if (this.shopId) {
      let requestAgrement = {
        shopId: String(this.shopId)
      } as GetAgreementRequest;
      await ShopAgreementAPI.get(requestAgrement);
      if (!ShopAgreementAPI.isSuccess) {
        await Flash.setErrorNow({
          message: "",
          showReloadButton: true
        } as ErrorAlert);
        return;
      } else {
        const AgreementItems = ShopAgreementAPI.getagreementsList;
        AgreementItems.forEach((item: { agreementsType: number }) => {
          if (item.agreementsType == 1) {
            this.agreements.ap = true;
          } else if (item.agreementsType == 2) {
            this.agreements.line = true;
          } else if (item.agreementsType == 3) {
            this.agreements.web = true;
          }
        });
      }
    } else {
      // スーパーユーザの場合は全て契約
      if (Admin.isAdmin) {
        this.agreements.ap = true;
        this.agreements.line = true;
        this.agreements.web = true;
      }
    }

    await this.get();
    this.syncParams();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await RecruitGetApi.clear();
    await RecruitRegisterAPI.clear();
  }

  setFile(data: string, id: number | null) {
    this.inputData.imageEditFlg = 1;
    this.inputData.imagePath = data;
    this.inputData.imageId = id;
  }

  unsetFile() {
    this.inputData.imageEditFlg = 1;
    this.inputData.imagePath = null;
    this.inputData.imageId = null;
  }

  async webPreview() {
    this.inputData.isPreview = 2;
    await this.callRegisterApi();
    if (RecruitRegisterAPI.isSuccess) {
      WindowOpen.webPreview(RecruitRegisterAPI.getPreviewUrl);
    }
  }
  
  async mounted() {
    const storage = BusinessServiceStorage.getLocalStorage();
    this.businessId = storage.id ? storage.id : 0;
  }

  /**
   * 求人情報の取得処理
   */
  async get(): Promise<boolean> {
    this.isLoading = true;
    if (RecruitGetApi.isSuccess) {
      return true;
    }
    await RecruitGetApi.clear();
    const request = this.createGetRequest();
    await RecruitGetApi.recruit(request);
    if (!RecruitGetApi.isSuccess) {
      await Flash.setErrorNow({
        message: RecruitGetApi.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
    return RecruitGetApi.isSuccess;
  }

  /**
   * 更新する
   */
  async register() {
    this.inputData.isPreview = 0;
    await this.callRegisterApi();
    if (RecruitRegisterAPI.isSuccess) {
      await this.redirectWithSuccessAlert(
          "保存しました。",
          "/recruit-management"
      );
    } else {
      await Flash.setErrorNow({
        message: RecruitRegisterAPI.getMessage,
        showReloadButton: false
      });
    }
  }

  private async callRegisterApi() {
    await Flash.clear();
    this.isLoading = true;
    await RecruitRegisterAPI.register(this.createRegisterRequest());
    if (!RecruitRegisterAPI.isSuccess) {
      await Flash.setErrorNow({
        message: RecruitRegisterAPI.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * dataから情報取得用のリクエストを作成する
   */
  private createGetRequest() {
    return {shopId: this.shopId} as RecruitRequest;
  }

  /**
   * dataから登録更新用のリクエストを作成する
   */
  private createRegisterRequest() {
    const request = _.cloneDeep(this.inputData);
    return request;
  }

  /**
   * APIのレスポンスをdataにsyncする
   */
  private syncParams() {
    this.inputData.id = RecruitGetApi.getId;
    this.inputData.shopId = RecruitGetApi.getShopId;
    this.inputData.imagePath = RecruitGetApi.getImagePath;
    this.inputData.jobApplyDetail = RecruitGetApi.getJobApplyDetail;
    this.inputData.desireJobRequiredFlg = RecruitGetApi.getDesireJobRequiredFlg;
    this.inputData.nameRequiredFlg = RecruitGetApi.getNameRequiredFlg;
    this.inputData.kanaRequiredFlg = RecruitGetApi.getKanaRequiredFlg;
    this.inputData.birthDateRequiredFlg = RecruitGetApi.getBirthDateRequiredFlg;
    this.inputData.genderRequiredFlg = RecruitGetApi.getGenderRequiredFlg;
    this.inputData.telRequiredFlg = RecruitGetApi.getTelRequiredFlg;
    this.inputData.emailRequiredFlg = RecruitGetApi.getEmailRequiredFlg;
    this.inputData.zipcodeRequiredFlg = RecruitGetApi.getZipcodeRequiredFlg;
    this.inputData.stateRequiredFlg = RecruitGetApi.getStateRequiredFlg;
    this.inputData.municipalitiesRequiredFlg = RecruitGetApi.getMunicipalitiesRequiredFlg;
    this.inputData.buildingRequiredFlg = RecruitGetApi.getBuildingRequiredFlg;
    this.inputData.remarksRequiredFlg = RecruitGetApi.getRemarksRequiredFlg;
    this.inputData.desireJobType1 = RecruitGetApi.getDesireJobType1;
    this.inputData.desireJobType2 = RecruitGetApi.getDesireJobType2;
    this.inputData.desireJobType3 = RecruitGetApi.getDesireJobType3;
    this.inputData.desireJobType4 = RecruitGetApi.getDesireJobType4;
    this.inputData.desireJobType5 = RecruitGetApi.getDesireJobType5;
    this.inputData.desireJobType6 = RecruitGetApi.getDesireJobType6;
    this.inputData.desireJobType7 = RecruitGetApi.getDesireJobType7;
    this.inputData.desireJobType8 = RecruitGetApi.getDesireJobType8;
    this.inputData.desireJobType9 = RecruitGetApi.getDesireJobType9;
    this.inputData.desireJobType10 = RecruitGetApi.getDesireJobType10;
    this.inputData.sendCompletedMessage = RecruitGetApi.getSendCompletedMessage;
    this.inputData.jobApplySendingEmail = RecruitGetApi.getJobApplySendingEmail;
    this.initImageUrl = this.inputData.imagePath;
  }
}